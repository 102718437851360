import React, { Component } from 'react';
import LoaderButton from "./LoaderButton";
import { API } from "aws-amplify";
import { Modal, FormGroup, FormControl, ControlLabel, Glyphicon } from "react-bootstrap";

class CreateFolder extends Component{
    constructor(props) {
        super(props);
        
        this.state = {
            folderName: "",
            isLoading: false
        };
    }
    
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    validateForm() {
        return(
            this.state.folderName.length > 0 
        );
    }
    
    handleClose() {
        this.setState({ folderName: "" });
        this.props.props.handleNewFolder(false);
    }
    
    apiCreateObjectFolder(requiredAttributes) {
        return API.post("vault", "/create-object/folder", {
            body: requiredAttributes
        });
    }
    
    handleSubmit = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            var requiredAttributes = {};
            
            if(this.props.props.directoryPath !== undefined){
                requiredAttributes = {
                ObjectName: this.state.folderName,
                ObjectDirectory: this.props.props.directoryPath
                };
            }else {
                requiredAttributes = {
                ObjectName: this.state.folderName
                };
            }
            
            await this.apiCreateObjectFolder(requiredAttributes);
            
            this.setState({ 
                isLoading: false,
                folderName: ""});
            this.props.props.handleNewFolderRefresh(false);
            
        } catch(e) {
            alert("We could not create your folder right now. ");
            this.setState({ isLoading: false });
        }
    }
    
    render() {
        return(
            <Modal show={this.props.props.showNewFolder} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Folder
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.handleSubmit}>
                        <FormGroup controlId="folderName">
                            <ControlLabel>Folder Name</ControlLabel>
                            <FormControl    
                                    type="text"
                                    value={this.state.folderName}
                                    onChange={this.handleChange}
                                />
                        </FormGroup>
                        <LoaderButton 
                            block
                            bsStyle="primary"
                            disabled={!this.validateForm()}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text={<div><Glyphicon glyph="folder-open"/> Save</div>}
                            loadingText="Saving..."
                         />
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default CreateFolder;