import React, { Component } from 'react';
import { ButtonToolbar, DropdownButton, MenuItem, Glyphicon } from "react-bootstrap";

class BodyMenu extends Component {
    render(){
        return(
            <ButtonToolbar className="pull-right">
                <DropdownButton title={<div style={{display: "inline-block"}}><Glyphicon glyph="pencil" /> New</div>} id="bg-nested-dropdown" bsStyle="success" bsSize="small" pullRight>
                    <MenuItem onClick={() => this.props.props.handleNewFolder(true)}><Glyphicon glyph="folder-open" /> Folder</MenuItem>
                    <MenuItem onClick={() => this.props.props.handleNewFile(true)}><Glyphicon glyph="file" /> File Upload</MenuItem>
                </DropdownButton>
            </ButtonToolbar>    
        );
    }
}

export default BodyMenu;