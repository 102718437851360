import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel, HelpBlock, Button, Alert} from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";

const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };

class MyAccount extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "None",
            isLoading: false,
            showAlert: false
        };
    }
    
    validateForm() {
        return(
            this.state.firstName.length > 0 &&
            this.state.lastName.length > 0
        );
    }
    
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    handleTimer() {
        setTimeout(() => {
            this.props.history.push("/");
        }, 3000);
    }
    
    handleSubmit = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            var requiredAttributes = {
                "family_name": this.state.lastName, 
                "name": this.state.firstName,
                "email": this.state.email
            };
            
            await Auth.currentAuthenticatedUser().then(user => {
                return Auth.updateUserAttributes(user, requiredAttributes);
            });
            
            this.setState({ showAlert: true });
            
        } catch(e) {
            alert("We could not update your information right now. ");
            this.setState({ isLoading: false });
        }
    }
    
    handleCancel = event => {
        this.props.history.push("/");
    }
    
    async componentDidMount() {
        try {
            await (await Auth.currentCredentials()).getPromise();
            var response = await Auth.currentUserInfo();
            
            this.setState({ firstName: response.attributes.name });
            this.setState({ lastName: response.attributes.family_name });
            this.setState({ email: response.attributes.email });
        } catch(e) {
            alert("Not Logged In");
        }
    }
    
    render(){
        if (this.state.showAlert) {
            this.handleTimer();
            return(
                <div className="MyAccount" style={wellStyles}>
                    <h2 align="center">My Account</h2>
                    <Alert bsStyle="success">
                        <strong>Your Account was Updated</strong><br />
                        You will now be returned to the home page
                    </Alert>
                </div>
            );
        }
        return(
            <div className="MyAccount" style={wellStyles}>
                <h2 align="center">My Account</h2>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="email">
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            disabled
                        />
                        <HelpBlock>This field is not changeable at this time.</HelpBlock>
                    </FormGroup>
                    <FormGroup controlId="firstName">
                        <ControlLabel>First Name</ControlLabel>
                        <FormControl
                            type="text"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="lastName">
                        <ControlLabel>Last Name</ControlLabel>
                        <FormControl
                            type="text"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="phoneNumber">
                        <ControlLabel>Phone Number</ControlLabel>
                        <FormControl
                            type="tel"
                            value={this.state.phoneNumber}
                            onChange={this.handleChange}
                            disabled
                        />
                        <HelpBlock>This field is not changeable at this time.</HelpBlock>
                    </FormGroup>
                    <LoaderButton 
                        block
                        bsStyle="primary"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving..."
                    />
                    <Button block onClick={this.handleCancel}>Cancel</Button>
                </form>
            </div>
        );
    }
}

export default MyAccount;