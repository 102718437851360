import React from "react";
import { Route, Switch } from "react-router-dom";
import DisplayHeader from "./Components/DisplayHeader";
import Login from "./Components/Login";
import NotFound from "./Components/NotFound";
import ChangePassword from "./Components/ChangePassword";
import ForgotPassword from "./Components/ForgotPassword";
import MyAccount from "./Components/MyAccount";
import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import UnauthenticatedRoute from "./Components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AuthenticatedRoute path="/" exact component={DisplayHeader} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <AuthenticatedRoute path="/password" exact component={ChangePassword} props={childProps} />
    <UnauthenticatedRoute path="/forgotpassword" exact component={ForgotPassword} props={childProps} />
    <AuthenticatedRoute path="/myaccount" exact component={MyAccount} props={childProps} />
    <AuthenticatedRoute path="/:folder" exact component={DisplayHeader} props={childProps} />
    <Route component={NotFound} />
  </Switch>;