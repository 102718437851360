import React, { Component } from 'react';
import "./DisplayDetails.css";
import { Modal, FormGroup, FormControl, ControlLabel, Button, ButtonToolbar, Glyphicon } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import { API, Storage } from "aws-amplify";

class DisplayDetails extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            confirmDelete: false
        };
    }
    
    validateForm() {
        return(
            this.props.props.detailObjectName.length > 0
        );
    }
    
    apiUpdateObjectSet(itemid, requiredAttributes) {
        return API.put("vault", "/update-object/set/" + itemid, {
            body: requiredAttributes
        });
    }
    
    apiUpdateObjectRemove(itemid, requiredAttributes) {
        return API.put("vault", "/update-object/remove/" + itemid, {
            body: requiredAttributes
        });
    }
    
    apiDeleteObject(itemid) {
        return API.del("vault", "/delete-object/" + itemid);
    }
    
    apiGetObject(itemid) {
        return API.get("vault", "/get-object/" + itemid);
    }
    
    s3GetObject(key) {
        return Storage.vault.get(key, {expires: 60});
    }
    
    handleGetObject = async event => {
        event.preventDefault();
        
        try {
            var itemid = this.props.props.detailObjectId;
            var response = await this.apiGetObject(itemid);
            var s3key = response['Key'];
            var signedUrl = await this.s3GetObject(s3key);
            
            var link = document.createElement('a');
            link.download = this.props.props.detailObjectName;
            link.href = signedUrl;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
        }catch(e){
            alert(e);
        }
    }
    
    handleClearDescription = async event => {
        event.preventDefault();
        
        try {
            var list = ['ObjectDescription'];
            var requiredAttributes = {attributes: list};
            var itemid = this.props.props.detailObjectId;
            
            await this.apiUpdateObjectRemove(itemid, requiredAttributes);
            
            this.props.props.handleClearDescription(this.props.props.detailObjectId);
        }catch(e){
            alert(e);
        }
    }
    
    handleSet = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            var requiredAttributes = {};
            
            if (this.props.props.nameChanged && this.props.props.descriptionChanged){
                requiredAttributes = {
                    ObjectDescription: this.props.props.detailObjectDescription,
                    ObjectName: this.props.props.detailObjectName
                };
            }else if(this.props.props.descriptionChanged){
                requiredAttributes = {
                    ObjectDescription: this.props.props.detailObjectDescription
                };
            }else if(this.props.props.nameChanged){
                requiredAttributes = {
                    ObjectName: this.props.props.detailObjectName
                };
            }else{
                this.props.props.handleCloseDetails(false);
                this.setState({ isLoading: false });
                return;
            }
            
            var itemid = this.props.props.detailObjectId;
            
            await this.apiUpdateObjectSet(itemid, requiredAttributes);
            
            this.setState({ isLoading: false });
            this.props.props.handleCloseDetailsRefresh(false);
            
        }catch(e){
            alert(e);
            this.setState({ isLoading: false });
        }
    }
    
    handleConfirmDelete = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            var itemid = this.props.props.detailObjectId;
            
            await this.apiDeleteObject(itemid);
            
            this.setState({ 
                isLoading: false,
                confirmDelete: false
            });
            this.props.props.handleCloseDetailsRefresh(false);
        }catch(e) {
            alert(e);
            this.setState({ 
                isLoading: false,
                confirmDelete: false
            });
        }
    }
    
    handleDelete = event => {
        this.setState({ confirmDelete: true });
    }
    
    handleCancelDelete = event => {
        this.setState({ confirmDelete: false });
    }
    
    modalFooterFile(){
        return(
            <Modal.Footer>
                <ButtonToolbar className="pull-right">
                    <Button bsStyle="danger" onClick={this.handleDelete}><div><Glyphicon glyph="trash" /> Delete</div></Button>
                    <LoaderButton 
                            bsStyle="primary"
                            disabled={!this.validateForm()}
                            onClick={this.handleSet}
                            isLoading={this.state.isLoading}
                            text={<div><Glyphicon glyph="ok" /> Save</div>}
                            loadingText="Saving..."
                        />
                    <Button bsStyle="success" onClick={this.handleGetObject}><div><Glyphicon glyph="cloud-download" /> Download</div></Button>
                    
                </ButtonToolbar>
            </Modal.Footer>
        );
    }
    
    modalFooterFolder(){
        return(
            <Modal.Footer>
                <ButtonToolbar className="pull-right">
                    <Button bsStyle="danger" onClick={this.handleDelete}><div><Glyphicon glyph="trash" /> Delete</div></Button>
                    <LoaderButton 
                            bsStyle="primary"
                            disabled={!this.validateForm()}
                            onClick={this.handleSet}
                            isLoading={this.state.isLoading}
                            text={<div><Glyphicon glyph="ok" /> Save</div>}
                            loadingText="Saving..."
                        />
                    
                </ButtonToolbar>
            </Modal.Footer>
        );
    }
    
    render(){
        if(this.state.confirmDelete){
            return(
                <Modal show={this.props.props.showDetails} onHide={() => this.props.props.handleCloseDetails(false)}>
                <Modal.Header>
                    <Modal.Title>
                        Confirm Delete
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 align="center">Are you sure you want to delete this object?</h4>
                    <ButtonToolbar>
                    <LoaderButton 
                            block
                            bsStyle="danger"
                            onClick={this.handleConfirmDelete}
                            isLoading={this.state.isLoading}
                            text={<div><Glyphicon glyph="trash" /> Delete</div>}
                            loadingText="Deleting..."
                        />
                    <Button block onClick={this.handleCancelDelete}>Cancel</Button>
                    </ButtonToolbar>
                </Modal.Body>
                </Modal>
            );
        }
        
        return(
            <Modal show={this.props.props.showDetails} onHide={() => this.props.props.handleCloseDetails(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <FormGroup controlId="detailObjectName">
                            <ControlLabel>Name</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.props.props.detailObjectName}
                                onChange={this.props.props.handleChange}
                            />
                        </FormGroup>
                        <fieldset>
                            <p>
                                <strong>Creation Date:</strong> {new Date(this.props.props.detailObjectCreationDate).toLocaleString()}<br/>
                                <strong>Date Modified:</strong> {new Date(this.props.props.detailObjectModifiedDate).toLocaleString()}<br/>
                                <strong>Storage Class:</strong> {this.props.props.detailObjectStorage}<br/>
                                <strong>Size:</strong> {this.props.props.detailObjectSize}<br/>
                            </p>
                        </fieldset>
                        <FormGroup className="object-description" controlId="detailObjectDescription">
                            <ControlLabel>Description</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.props.props.detailObjectDescription}
                                onChange={this.props.props.handleChange}
                                componentClass="textarea"
                            />
                        </FormGroup>
                        <Button bsStyle="link" bsSize="xsmall" onClick={this.handleClearDescription}>Delete Description</Button>
                    </form>
                </Modal.Body>
                {this.props.props.detailObjectType === 'F' ? this.modalFooterFile() : this.modalFooterFolder()}
            </Modal>
        );
    }
}

export default DisplayDetails;