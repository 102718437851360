import React, { Component } from 'react';
import "./DisplayBody.css";
import { Button, Glyphicon, Table } from "react-bootstrap";
import { API } from "aws-amplify";
import DisplayDetails from "./DisplayDetails";
import BodyMenu from "./BodyMenu";
import CreateFolder from "./CreateFolder";
import CreateFile from "./CreateFile";

const wellStyles = { margin: '10px' };

class DisplayBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            objects: [],
            badDirectory: false,
            showNewFolder: false,
            showNewFile: false,
            showDetails: false,
            detailObjectName: "",
            detailObjectCreationDate: "",
            detailObjectModifiedDate: "",
            detailObjectDescription: null,
            detailObjectStorage: "",
            detailObjectSize: "",
            detailObjectType: "",
            detailObjectId: "",
            nameChanged: false,
            descriptionChanged: false
        };
    }
    
    async componentDidMount() {
        await this.bodyLoader();
    }
    
    async bodyLoader(){
        try {
            var response = await this.apiListObjects();
            this.setState({ objects: response['objects'] });
        } catch (e) {
            this.setState({ badDirectory: true });
        }

        this.setState({ isLoading: false });
    }
    
    apiListObjects() {
        if(this.props.props.folder !== undefined){
            return API.get("vault", "/list-objects/" + this.props.props.folder);
        }else {
            return API.get("vault", "/list-objects");
        }
    }
    
    bytesToSize(bytes) {
        bytes = parseInt(bytes, 10);
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '-';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        if (i === 0) return `${bytes} ${sizes[i]})`;
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
    }
    
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
        

        if(event.target.id === 'detailObjectName'){
            this.setState({ nameChanged: true });
        }else if(event.target.id === 'detailObjectDescription'){
            this.setState({ descriptionChanged: true });
        }
    }
    
    handleNewFolder = status => {
        this.setState({ showNewFolder: status });
    }
    
    handleNewFolderRefresh = async status => {
        this.setState({ 
            showNewFolder: status, 
            isLoading: true
        });
        
        await this.bodyLoader();
        
        this.renderObjects();
    }
    
    handleNewFile = status => {
        this.setState({ showNewFile: status });
    }
    
    handleNewFileRefresh = async status => {
        this.setState({ 
            showNewFile: status, 
            isLoading: true
        });
        
        await this.bodyLoader();
        
        this.renderObjects();
    }
    
    handleDetails(object) {
        this.setState({ detailObjectName: object['ObjectName'] });
        this.setState({ detailObjectCreationDate: object['CreationDate'] });
        this.setState({ detailObjectModifiedDate: object['LastModifiedDate'] });
        this.setState({ detailObjectDescription: object['ObjectDescription'] });
        this.setState({ detailObjectStorage: object['ObjectStorage'] });
        this.setState({ detailObjectSize: this.bytesToSize(object['ObjectSize']) });
        this.setState({ detailObjectType: object['ObjectType'] });
        this.setState({ detailObjectId: object['ObjectId'] });
        
        this.setState({ showDetails: true });
    }
    
    handleCloseDetails = close => {
        this.setState({ 
            showDetails: close ,
            nameChanged: false,
            descriptionChanged: false
        });
    }
    
    handleCloseDetailsRefresh = async close => {
        this.setState({ 
            showDetails: close, 
            isLoading: true,
            nameChanged: false,
            descriptionChanged: false
        });
        
        await this.bodyLoader();
        
        this.renderObjects();
    }
    
    handleClearDescription = async event => {
        this.setState({ detailObjectDescription: "" });

        await this.bodyLoader();
    }
    
    handleFolderClick = event => {
        this.props.history.push(event.currentTarget.getAttributes("href"));
    }
    
    renderObjectsList(objects) {
        objects.sort(function(a,b) {return (a['ObjectName'] > b['ObjectName']) ? 1 : ((b['ObjectName'] > a['ObjectName']) ? -1 : 0); });
        
        return [].concat(objects).map(
            (object, i) =>
                <tr key={object['ObjectId']}>
                    <td width="15px">{object['ObjectType'] === 'D' ? <Glyphicon glyph="folder-open" /> : <Glyphicon glyph="file" />}</td>
                    <td width="15px">{object['PublicKey'] ? <Glyphicon glyph="lock" /> : ""}</td>
                    <td>{object['ObjectType'] === 'D' ? <Button className="btn-table" bsStyle="link" href={"/" + object['ObjectId']}>{object['ObjectName']}</Button> : object['ObjectName']}</td>
                    <td width="175px">{new Date(object['LastModifiedDate']).toLocaleString()}</td>
                    <td width="100px">{this.bytesToSize(object['ObjectSize'])}</td>
                    <td width="15px"><Button bsStyle="link" className="btn-table" onClick={() => {this.handleDetails(object)}}><Glyphicon glyph="edit" /></Button></td>
                </tr>
        );
    }
    
    renderObjects() {
        return (
            <div className="objects">
                <h4>Your Objects</h4>
                <Table hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Name</th>
                            <th>Last Modified</th>
                            <th>Size</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!this.state.isLoading && this.renderObjectsList(this.state.objects)}
                    </tbody>
                </Table>
          </div>
        );
    }
    
    renderLander() {
        return (
            <div className="lander" align="center">
                <h1>Welcome</h1>
                <p>Use the <strong>New</strong> button to get started</p>
            </div>
        );
    }
    
    renderLoader() {
        return(
            <div className="loader" align="center">
                <h1>Loading...</h1>
            </div>
        );
    }
    
    renderBadDirectory() {
        return(
            <div className="lander" align="center">
                <h1>Uh-Oh</h1>
                <p>We couldn't find the folder you're looking for.</p>
            </div>
        );
    }
    
    render() {
        var MenuControl = {
            handleNewFolder: this.handleNewFolder,
            handleNewFile: this.handleNewFile
        };
        var details = {
            showDetails: this.state.showDetails,
            detailObjectName: this.state.detailObjectName,
            detailObjectCreationDate: this.state.detailObjectCreationDate,
            detailObjectModifiedDate: this.state.detailObjectModifiedDate,
            detailObjectDescription: this.state.detailObjectDescription,
            detailObjectStorage: this.state.detailObjectStorage,
            detailObjectSize: this.state.detailObjectSize,
            detailObjectType: this.state.detailObjectType,
            detailObjectId: this.state.detailObjectId,
            nameChanged: this.state.nameChanged,
            descriptionChanged: this.state.descriptionChanged,
            handleCloseDetails: this.handleCloseDetails,
            handleCloseDetailsRefresh: this.handleCloseDetailsRefresh,
            handleClearDescription: this.handleClearDescription,
            handleChange: this.handleChange
        };
        var folderDetails = {
            showNewFolder: this.state.showNewFolder,
            directoryPath: this.props.props.folder,
            handleNewFolder: this.handleNewFolder,
            handleNewFolderRefresh: this.handleNewFolderRefresh
        };
        var fileDetails = {
            showNewFile: this.state.showNewFile,
            directoryPath: this.props.props.folder,
            handleNewFile: this.handleNewFile,
            handleNewFileRefresh: this.handleNewFileRefresh
        };

        if(this.state.badDirectory){
            return(
            <div className="Home">
                <div className="Objects" style={wellStyles}>
                    {this.renderBadDirectory()}
                </div>
            </div>
        );
        }
        
        return(
            <div className="Home">
                <div className="ControlButtons" align="right" style={wellStyles}>
                    <BodyMenu props={MenuControl}/>
                </div><br/>
                <div className="Objects" style={wellStyles}>
                    {this.state.isLoading ? this.renderLoader() : (this.state.objects.length > 0 ? this.renderObjects() : this.renderLander())}
                </div>
                <div className="Details">
                    <DisplayDetails props={details}/>
                </div>
                <div className="NewFolder">
                    <CreateFolder props={folderDetails} />
                </div>
                <div className="NewFile">
                    <CreateFile props={fileDetails} />
                </div>
            </div>
        );
    }
}

export default DisplayBody;