import React, { Component } from 'react';
import { FormGroup, FormControl, Alert, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";

const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            password: "",
            newPassword: "",
            confirmPassword: "",
            showAlert: false
        };
    }
    
    validateForm() {
        return(
            this.state.password.length > 0 &&
            this.state.newPassword.length > 0 &&
            this.state.newPassword === this.state.confirmPassword
        );
    }
    
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    handleCancel = event => {
        this.props.history.push("/");
    }
    
    handleTimer() {
        setTimeout(() => {
            this.props.history.push("/");
        }, 3000);
    }
    
    handleSubmit = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            await Auth.currentAuthenticatedUser().then(user => {
                return Auth.changePassword(user, this.state.password, this.state.newPassword);
            });
            
            this.setState({ showAlert: true });
            
        } catch(e) {
            alert(e);
            this.setState({ isLoading: false });
        }
    }
    
    render() {
        if (this.state.showAlert) {
            this.handleTimer();
            return(
                <div className="ChangePassword" style={wellStyles}>
                    <h2 align="center">Change Password</h2>
                    <Alert bsStyle="success">
                        <strong>Your Password was Updated</strong><br />
                        You will now be returned to the home page
                    </Alert>
                </div>
            );
        }
        return(
            <div className="ChangePassword" style={wellStyles}>
                <h2 align="center">Change Password</h2>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="password">
                        <FormControl
                            autoFocus
                            type="password"
                            placeholder="Current Password"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="newPassword">
                        <FormControl
                            type="password"
                            placeholder="New Password"
                            value={this.state.newPassword}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="confirmPassword">
                        <FormControl
                            type="password"
                            placeholder="Confirm New Password"
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <LoaderButton 
                        block
                        bsStyle="primary"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving..."
                    />
                    <Button block onClick={this.handleCancel}>Cancel</Button>
                </form>
            </div>
        );
    }
}

export default PasswordReset;