import React, { Component } from 'react';
import LoaderButton from "./LoaderButton";
import { API, Storage } from "aws-amplify";
import { Modal, FormGroup, FormControl, ControlLabel, ProgressBar, Button, Glyphicon } from "react-bootstrap";

class CreateFile extends Component{
    constructor(props) {
        super(props);
        
        this.file = null;
        
        this.state = {
            isLoading: false,
            isUploading: false,
            isProcessing: false
        };
    }
    
    handleClose() {
        this.file = null;
        this.props.props.handleNewFile(false);
    }
    
    handleFileChange = event => {
        this.file = event.target.files[0];
    }
    
    handleTimer() {
        setTimeout(() => {
            this.props.props.handleNewFileRefresh(false);
            this.setState({ 
                isLoading: false, 
                isProcessing: false
            });
        }, 3000);
    }
    
    apiCreateObjectFile(requiredAttributes) {
        return API.post("vault", "/create-object/file", {
            body: requiredAttributes
        });
    }
    
    apiCompleteUpload(key){
        return API.put("vault", "/create-object/complete-upload/" + key, {
            body: { body: "emptyBody" }
        });
    }
    
    async s3PutObject(key){
        return await Storage.vault.put(key, this.file, {
            contentType: this.file.type
        });
    }
    
    handleSubmit = async event => {
        event.preventDefault();
        
        if(this.file == null){
            this.handleClose();
            return;
        }
        
        this.setState({ isLoading: true });

        try {
            var requiredAttributes = {};
            
            if(this.props.props.directoryPath !== undefined){
                requiredAttributes = {
                    ObjectName: this.file['name'],
                    ObjectDirectory: this.props.props.directoryPath
                };
            }else {
                requiredAttributes = {
                    ObjectName: this.file['name']
                };
            }
            
            var response = await this.apiCreateObjectFile(requiredAttributes);
            
            this.setState({ isUploading: true });
            await this.s3PutObject(response['Key']);
            
            this.setState({
                isUploading: false,
                isProcessing: true
            });
            await this.apiCompleteUpload(response['Key']);
            
            this.handleTimer();
        }catch(e) {
            alert(e);
            this.setState({ 
                isLoading: false, 
                isUploading: false,
                isProcessing: false
            });
        }
    }
    
    render() {
        if(this.state.isUploading === true){
            return(
                <Modal show={this.props.props.showNewFile} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            New File Upload
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>Your file is Being Uploaded</h3>
                        <ProgressBar active now={100} />
                    </Modal.Body>
                </Modal>
            );
        }
        
        if(this.state.isProcessing === true){
            return(
                <Modal show={this.props.props.showNewFile} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            New File Upload
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>Processing your Upload</h3>
                        <ProgressBar active now={100} />
                    </Modal.Body>
                </Modal>
            );
        }
        
        return(
            <Modal show={this.props.props.showNewFile} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        New File Upload
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.handleSubmit}>
                        <FormGroup controlId="file">
                            <ControlLabel>Select File</ControlLabel>
                            <FormControl 
                                type="file"
                                label="File"
                                onChange={this.handleFileChange}
                            />
                        </FormGroup>
                        <LoaderButton 
                            block
                            bsStyle="primary"
                            type="submit"
                            isLoading={this.state.isLoading}
                            text={<div><Glyphicon glyph="cloud-upload" /> Upload</div>}
                            loadingText="Uploading..."
                         />
                         <Button disabled block bsStyle="warning"><div><Glyphicon glyph="lock" /> Encrypted Upload</div></Button>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default CreateFile;