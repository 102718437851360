export default {
  s3: {
    REGION: "us-east-2",
    BUCKET: "vault-east"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://vault-east.api.ilhardt.org/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_fwxDt1cwW",
    APP_CLIENT_ID: "72bnhqqnvcv34a7hkl4t6dkv2u",
    IDENTITY_POOL_ID: "us-east-2:ded95057-f89b-4fda-97f3-99e9ed4fc208"
  }
};