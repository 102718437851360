import React, { Component } from 'react';
import logo from '../logo.png';
import './DisplayHeader.css';
import {Navbar, Nav, NavItem, NavDropdown, MenuItem} from 'react-bootstrap';
import { Auth } from "aws-amplify";
import DisplayBody from "./DisplayBody";

class DisplayHeader extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            displayName: "",
        };
    }
    
    async componentDidMount() {

        try {
            await (await Auth.currentCredentials()).getPromise();
            var response = await Auth.currentUserInfo();
            
            this.setState({ displayName: response.attributes.name });
            
        } catch(e) {
            alert("Not Logged In");
        }
    }
    
    handleLogout = async event => {
        try {
            await Auth.signOut();
        
            this.props.userHasAuthenticated(false);
            this.props.history.push("/login");
        } catch(e){
            alert("Already Logged Out");
            this.props.history.push("/login");
        }
        
    }
    
    changePassword = event => {
        this.props.history.push("/password");
    }
    
    myAccount = event => {
        this.props.history.push("/myaccount");
    }
    
    render(){
        const childProps = {
            isAuthenticated: this.props.isAuthenticated,
            folder: this.props.match.params.folder
        };
        return(
            <div className="App">
                <div className="border-bottom box-shadow">
                    <Navbar color="faded">
                        <Navbar.Header>
                                <Navbar.Brand>
                                    <a href="/"><img src={logo} className="img-responsive" alt="Ilhardt Logo" />Vault</a>
                                </Navbar.Brand>
                                <Navbar.Toggle />
                        </Navbar.Header>
                        <Navbar.Collapse>
                            <Nav pullRight>
                                <NavItem href="#">
                                    Docs
                                </NavItem>
                                <NavDropdown title={this.state.displayName} id="basic-nav-dropdown">
                                    <MenuItem onClick={this.myAccount}>My Account</MenuItem>
                                    <MenuItem onClick={this.changePassword}>Change Password</MenuItem>
                                    <MenuItem divider />
                                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
                <div className="Body">
                    <DisplayBody props={childProps} />
                </div>
            </div>
        );
    }
}

export default DisplayHeader;