import React, { Component } from 'react';
import logo from '../logo.png';
import { Button, FormGroup, FormControl } from "react-bootstrap";
import "./Login.css";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";

const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };

class Login extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            email: "",
            password: "",
            newUser: false,
            newPassword: "",
            confirmPassword: "",
            firstName: "",
            lastName: ""
        };
    }
    
    validateForm(){
        return this.state.email.length > 0 && this.state.password.length > 0;
    }
    
    validateConfirmationForm() {
        return (
            this.state.newPassword.length > 0 && 
            this.state.firstName.length > 0 &&
            this.state.lastName.length > 0 &&
            this.state.newPassword === this.state.confirmPassword
        );
    }
    
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    handleForgotPassword = event => {
        this.props.history.push("/forgotpassword");
    }
    
    handleSubmit = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            var response = await Auth.signIn(this.state.email, this.state.password);
            
            if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.setState({ newUser: true });
                this.setState({ isLoading: false });
            } else {
                this.props.userHasAuthenticated(true);
            }

        } catch(e) {
            alert("Incorrect Username or Password");
            this.setState({ isLoading: false });
        }
    }
    
    handleNewUser = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            var requiredAttributes = {
                "family_name": this.state.lastName, 
                "name": this.state.firstName,
                "email": this.state.email
            };
            
            await Auth.signIn(this.state.email, this.state.password).then(user => {
                return Auth.completeNewPassword(user, this.state.newPassword, requiredAttributes);
            });
            
            this.setState({ newUser: false });
            this.props.userHasAuthenticated(true);
            this.props.history.push("/");
        } catch(e) {
            alert(e);
            this.setState({ isLoading: false });
        }
    }
    
    renderNewUser(){
        return(
            <form onSubmit={this.handleNewUser}>
                <FormGroup controlId="newPassword">
                    <FormControl 
                        autoFocus
                        type="password"
                        placeholder="New Password"
                        value={this.state.newPassword}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <FormGroup controlId="confirmPassword">
                    <FormControl 
                        type="password"
                        placeholder="Confirm Password"
                        value={this.state.confirmPassword}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <FormGroup controlId="firstName">
                    <FormControl 
                        type="text"
                        placeholder="First Name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <FormGroup controlId="lastName">
                    <FormControl 
                        type="text"
                        placeholder="Last Name"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <LoaderButton 
                    block
                    bsStyle="primary"
                    disabled={!this.validateConfirmationForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Submit"
                    loadingText="Saving..."
                />
            </form>
        );
    }
    
    renderLogin(){
        return(
            <div className="renderLogin">
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="email" bsSize="large">
                        <FormControl
                            autoFocus
                            type="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                        <FormControl
                            type="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <LoaderButton 
                        bsStyle="primary" 
                        block 
                        bsSize="large" 
                        disabled={!this.validateForm()} 
                        type="submit" 
                        isLoading={this.state.isLoading}
                        text="Login" 
                        loadingText="Logging In..." 
                    />
                    <Button bsStyle="link" bsSize="xsmall" onClick={this.handleForgotPassword} >Forgot Password</Button>
                </form>
                <div className="btn-sso" align="center">
                    <Button href="http://vault.login.ilhardt.org">Single Sign-On</Button>
                </div>
            </div>
        );
    }
    
    render(){
        return(
            <div className="Login" style={wellStyles}>
                <img src={logo} className="img-responsive-large" alt="Ilhardt Logo" />
                <h1 align="center">Vault</h1>
                {this.state.newUser === false
                    ? this.renderLogin()
                    : this.renderNewUser()
                }
            </div>
        );
    }
}

export default Login;