import React, { Component } from 'react';
import logo from '../logo.png';
import "./ForgotPassword.css";
import { FormGroup, FormControl, HelpBlock } from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";

const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            email: "",
            password: "",
            confirmPassword: "",
            resetSent: false,
            confirmationCode: ""
        };
    }
    
    validateForm() {
        return(
            this.state.email.length > 0
        );
    }
    
    validateConfirmationForm() {
        return(
            this.state.confirmationCode.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }
    
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    handleSubmit = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            await Auth.forgotPassword(this.state.email);
            this.setState({ resetSent: true });
        } catch(e) {
            alert("We couldn't find your account");
        }
        
        this.setState({ isLoading: false });
    }
    
    handleConfirmSubmit = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            await Auth.forgotPasswordSubmit(this.state.email, this.state.confirmationCode, this.state.password);
            this.props.history.push("/login");
        } catch(e) {
            alert("Something went Wrong");
            this.setState({ isLoading: false });
        }
    }
    
    renderConfirmationForm() {
        return(
            <form onSubmit={this.handleConfirmSubmit}>
                <FormGroup controlId="confirmationCode">
                    <FormControl 
                        autoFocus
                        type="tel"
                        placeholder="Confirmation Code"
                        value={this.state.confirmationCode}
                        onChange={this.handleChange}
                    />
                    <HelpBlock>Please check your Email for your confirmation code.</HelpBlock>
                </FormGroup>
                <FormGroup controlId="password">
                    <FormControl 
                        type="password"
                        placeholder="New Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <FormGroup controlId="confirmPassword">
                    <FormControl 
                        type="password"
                        placeholder="Confirm Password"
                        value={this.state.confirmPassword}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <LoaderButton 
                    block
                    bsStyle="primary"
                    disabled={!this.validateConfirmationForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Submit"
                    loadingText="Saving..."
                />
            </form>
        );
    }
    
    renderForm() {
        return(
            <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="email" bsSize="large">
                    <FormControl
                        autoFocus
                        type="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                <LoaderButton 
                    block
                    bsStyle="primary"
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Send Email"
                    loadingText="Sending..."
                />
            </form>
        );
    }
    
    render() {
        return(
            <div className="ForgotPassword" style={wellStyles}>
            <img src={logo} className="img-responsive-large" alt="Ilhardt Logo" />
            <h1 align="center">Vault</h1>
            <h3 align="center">Forgot Password</h3>
                {this.state.resetSent === false
                    ? this.renderForm()
                    : this.renderConfirmationForm()
                }
            </div>
        );
    }
}

export default ForgotPassword;